.footer {
  padding: 44px 100px 22px;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
  &-bottom {
    &-box {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      max-width: 62%;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      align-items: center;
      & a {
        color: $txt-grey;
        font-family: Inter;
        font-size: 15px;
      }
      & div {
        width: 1px;
        height: 16px;
        background-color: $txt-grey;
        margin: 0 16px;
      }
    }
    &-center {
      color: $txt-grey;
      font-family: Inter;
      font-size: 15px;
    }
  }
}
.header__nav.inFooter {
  & .header__nav-item a {
    font-size: 15px;
  }
}

@media screen and (max-width: 1280px) {
  .footer {
    padding: 44px 50px 22px;

    & .header__nav-item a {
      font-size: 15px;
    }
    & .header__right-lang {
      font-size: 15px;
    }
    & .header__logo img {
      width: 198px;
      height: 42px;
    }
    & .socials a {
      &:not(:last-child) {
        margin-right: 14px;
      }
      & img {
        width: 38px;
        height: 38px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    padding: 44px 16px 22px;
    &-top {
      flex-direction: column;
      &-box {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(3) {
          order: 2;
        }
        &:nth-child(2) {
          order: 3;
        }
      }
    }
    &-bottom {
      flex-direction: column-reverse;
      &-box {
        display: flex;
        flex-direction: row-reverse;
        max-width: 100%;
        width: 100%;
        justify-content: space-around;
        margin-top: 30px;
        align-items: center;
      }
      &-left {
        margin-left: 40px;

        & a {
          text-align: center;
        }
      }
      &-center {
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .header__nav.inFooter {
    max-width: 400px;
    & .header__nav-item a {
      font-size: 15px;
    }
  }
  .footer {
    &-top {
      margin-bottom: 0;
      &-box {
        & .header__logo img {
          width: 124px;
          height: 26px;
        }
        & button {
          width: 126px;
        }
      }
    }
    &-bottom {
      flex-direction: column-reverse;
      &-box {
        display: flex;
        flex-direction: row-reverse;
        max-width: 100%;
        width: 100%;
        justify-content: space-around;
        margin-top: 30px;
        & a,
        & .footer-bottom-center {
          font-size: 11px;
          color: #c7c7c7;
        }
      }
    }
    & .header__nav {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px 40px;
    }
    & .header__nav-item {
      padding-left: 60px;
      width: calc(50% - 20px);
      margin-right: 0;
      &:nth-child(2),
      &:nth-child(4) {
        padding-left: 20px;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .footer {
    & .header__nav-item {
      padding-left: 40px;
    }
  }
}
@media screen and (max-width: 360px) {
  .footer {
    & .header__nav-item {
      padding-left: 20px;
      &:nth-child(2),
      &:nth-child(4) {
        padding-left: 0;
      }
    }
  }
}
