.plans {
  position: relative;
  padding: 120px 100px 60px;
  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 450px;
    height: 450px;
    border-radius: 50%;
    filter: blur(80px);
    opacity: 0.5;
  }
  &::before {
    background: #e2c766;
    top: 40%;
    left: -10%;
  }
  &::after {
    background: #2f1ae9;
    top: 15%;
    left: 55%;
  }
  &-title {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
    z-index: 1;
  }
  &-content {
    position: relative;
    max-width: 970px;
    margin: 0 auto;
    padding: 34px 50px;
    background-color: rgba(0, 3, 7, 0.33);
    border-radius: 16px;
    z-index: 1;
    &-banner {
      position: absolute;
      top: -11%;
      right: -10%;
      max-width: 235px;
      padding: 16px;
      background-color: $blue;
      border-radius: 20px;
      font-family: Inter;
      font-size: 18px;
      line-height: 140%;

      &::before {
        position: absolute;
        content: url(../../../public/assets/plans-img.webp);
        width: 200px;
        height: 200px;
        bottom: 4px;
        left: -25px;
      }
    }
    & li {
      display: flex;
      align-items: center;
      padding: 17px 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(163, 161, 183, 0.2);
      }
      & img {
        margin-right: 24px;
      }
      & p {
        color: $txt-white-low;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 165%;
        & b {
          color: $txt-white;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .plans {
    padding: 60px;
    &-content {
      &-banner {
        position: static;
        margin-top: 40px;
        max-width: 400px;
        &::before {
          width: auto;
          height: auto;
          bottom: -180px;
          left: 250px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .plans {
    padding: 60px 18px 90px;
    &-title {
      font-size: 32px;
    }
    &-content {
      padding: 3px 17px 17px;
      &-banner {
        position: static;
        margin-top: 30px;
        max-width: 240px;
        font-size: 11px;
        border-radius: 10px;
        &::before {
          width: auto;
          height: auto;
          bottom: -220px;
          left: 40px;
        }
      }
      & li {
        & p {
          font-size: 14px;
          line-height: 140%;
        }
        & img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
