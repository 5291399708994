.mentor {
  display: flex;
  position: relative;
  gap: 47px;
  justify-content: space-between;
  padding: 60px 100px 120px;
  &::before {
    position: absolute;
    content: '';
    width: 250px;
    height: 250px;
    top: 30%;
    left: 50%;
    background-color: #fff625;
    filter: blur(150px);
    z-index: -1;
  }
  &-title {
    font-family: Craftwork Grotesk;
    font-size: 40px;
    text-transform: uppercase;
    margin-bottom: 60px;
  }
  &-name {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700;
  }
  &-description {
    max-width: 430px;
    font-family: Inter;
    font-size: 16px;
    line-height: 165%;
    margin-bottom: 44px;
  }
  &-quote {
    display: flex;
    gap: 26px;
    &-right p {
      color: $txt-white-low;
      font-family: Inter;
      font-size: 16px;
      font-style: italic;
      font-weight: 300;
      line-height: 165%;
      &:first-child {
        margin-bottom: 16px;
      }
      & b {
        color: $txt-white;
      }
    }
  }
  &-left,
  &-right {
    flex-grow: 1;
  }
  &-left {
    width: calc(50% - 29px);
  }
  &-right {
    max-height: 436px;
    width: calc(50% - 29px);
    &-mob {
      display: none;
    }
    &-img-box {
      position: relative;
    }
    &-img {
      width: 100%;
      height: auto;
    }

    &-banner {
      position: absolute;
      max-width: 70%;
      padding: 15px 10px 15px 80px;
      bottom: 20px;
      left: 20px;
      border-radius: 9px;
      border: 0.881px solid rgba(255, 255, 255, 0.17);
      background: rgba(0, 0, 0, 0.5);
      font-family: Inter;
      font-size: 15.86px;
      font-weight: 400;
      line-height: 140%;
      & img {
        position: absolute;
        top: calc(50% - 24px);
        left: 17px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .mentor {
    padding: 60px;
    &::before {
    }
    &-title {
      font-size: 32px;
    }
    &-name {
      font-size: 18px;
    }
    &-description {
      font-size: 14px;
    }
    &-quote {
      &-right p {
        font-size: 14px;
      }
      & img {
        width: 56px;
        height: 56px;
      }
    }
    &-right {
      &-banner {
        font-size: 14px;
        max-width: 80%;
        & img {
        }
      }
    }
  }
}
@media screen and (max-width: 1124px) {
  .mentor {
    flex-direction: column;
    align-items: center;
    padding: 60px;
    &-title {
      font-size: 32px;
      margin-bottom: 30px;
    }
    &-name {
      font-size: 18px;
    }
    &-description {
      font-size: 14px;
    }
    &-quote {
      &-right p {
        font-size: 14px;
      }
      & img {
        width: 56px;
        height: 56px;
      }
    }
    &-left,
    &-right {
      max-width: 600px;
      width: 100%;
    }
    &-right {
      &-mob {
        display: block;
        margin-bottom: 20px;
      }
      &-desk {
        display: none;
      }
      &-banner {
        left: 10%;
        bottom: 12px;
        font-size: 14px;
        max-width: 80%;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .mentor {
    padding: 30px 16px 60px;
  }
}
@media screen and (max-width: 576px) {
  .mentor {
    &-name {
      font-size: 18px;
    }
    &-description {
      font-size: 14px;
    }
    &-quote {
      &-right p {
        font-size: 14px;
      }
    }

    &-right {
      &-banner {
        left: 10%;
        font-size: 10px;
        max-width: 80%;
        padding: 10px 10px 10px 45px;
        & img {
          width: 26px;
          height: 26px;
          top: calc(50% - 14px);
          left: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .mentor {
    &-right {
      &-banner {
        left: 5%;
        max-width: 90%;
      }
    }
  }
}
