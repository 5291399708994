// Reset

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

ul,
ol {
  list-style: none;
}

// Colors

$yellow: #f0d663;
$blue: #1543d2;
$dark-blue: #000f3d;
$txt-white: #ffffff;
$txt-grey: #c7c7c7;
$txt-white-low: rgba(255, 255, 255, 0.8);
$bg-color: #000307;

// global

body {
  background-color: $bg-color;
  color: $txt-white;
  font-size: 18px;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.section {
  padding: 60px 100px;
}
.last-sections {
  background-color: #1a1a1a;
  border-radius: 50px;
  overflow: hidden;
}

.title {
  font-family: 'Craftwork Grotesk', sans-serif;
  font-size: 40px;
  font-weight: 500;
  & b {
    font-weight: 900;
  }
}
.text {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.up-down {
  transform: rotate(180deg);
}
.hide {
  display: none !important;
}
.show {
  display: block;
  &-flex {
    display: flex;
  }
}
.scrollLock {
  overflow: hidden;
}
@media screen and (max-width: 1440px) {
  .container {
    overflow: hidden;
  }
}
@media screen and (max-width: 1300px) {
  .section {
    padding: 60px;
  }
}
@media screen and (max-width: 1024px) {
  .section {
    padding: 30px 16px;
  }
}
@font-face {
  font-family: craftwork grotesk;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Craftwork Grotesk'),
    url(https://fonts.cdnfonts.com/s/88961/CraftworkGrotesk-Regular.woff)
      format('woff');
}
@font-face {
  font-family: craftwork grotesk;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Craftwork Grotesk'),
    url(https://fonts.cdnfonts.com/s/88961/CraftworkGrotesk-Bold.woff)
      format('woff');
}
@font-face {
  font-family: craftwork grotesk;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Craftwork Grotesk'),
    url(https://fonts.cdnfonts.com/s/88961/CraftworkGrotesk-Heavy.woff)
      format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v13/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
