.socials {
  text-align: center;
  & a:not(:last-child) {
    margin-right: 14px;
  }
  & a img {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  & a:hover img {
    transform: scale(1.05);
  }
}
