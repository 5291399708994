.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 3, 7, 0.6);
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  overflow: auto;
  &-content {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 90%;
    overflow: auto;
    position: relative;
    background-color: $txt-white;
    border-radius: 20px;
    padding: 70px;
  }
  &-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
@media screen and (max-width: 576px) {
  .modal {
    &-content {
      padding: 80px 16px 40px;
    }
  }
}
@media screen and (min-height: 700px) {
  .modal {
    align-items: center;
  }
}
