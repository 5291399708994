.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 13px 50px;
  height: 68px;
  z-index: 100;

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
  }
  &__logo {
    display: flex;
    align-items: center;
  }

  &__nav {
    display: flex;
    align-items: center;

    &-item {
      & a {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        color: $txt-grey;
        cursor: pointer;
        transition: color 0.2s ease-in-out;
        &:hover {
          color: $yellow;
          opacity: 1;
        }
      }
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    &-lang {
      position: relative;
      height: 21px;
      padding-right: 26px;
      font-size: 15px;
      font-family: 'Inter', sans-serif;
      color: $txt-grey;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-right: 1px solid #4d4949;
    }
    & img {
      margin-left: 3px;
    }
    & button {
      width: 119px;
      height: 41px;
      border: none;
      cursor: pointer;
      background-color: $blue;
      color: $txt-white;
      font-family: Inter;
      font-size: 15px;
      font-weight: 400;
      border-radius: 8px;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  &__lang-options {
    position: absolute;
    left: 0;
    bottom: -100%;
    color: $txt-grey;
    &:hover {
      color: $txt-white;
      transition: all 0.3 linear;
    }
  }
  &__burger-btn {
    display: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 14px 20px 20px;

    &__logo img {
      width: 124px;
      height: 26px;
    }
    &__nav.inHeader {
      display: none;
    }
    &__right.inHeader {
      display: none;
    }
    &__nav.inBurger {
      margin: 0 auto;
      flex-direction: column;
      padding: 40px 0;
      & .header__nav-item {
        margin-right: 0;
        &:not(:last-child) {
          margin-bottom: 38px;
        }
        & a {
          font-size: 28px;
        }
      }
    }
    &__right.inBurger {
      width: 240px;
      margin: 0 auto;
      & button {
        width: 160px;
      }
    }
    &__burger-btn {
      display: block;
    }
  }
}
@media screen and (min-width: 768px) {
  .header {
    &__nav.inFooter {
      & .header__nav-item {
        position: relative;
        &:not(:last-child) {
          &::before {
            position: absolute;
            content: '';
            top: 0;
            right: -20px;
            width: 1px;
            height: 20px;
            background-color: #4d4949;
          }
        }
      }
    }
  }
}
