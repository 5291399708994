.tariff {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: #2f1ae9;
    top: 0;
    right: 0;
    filter: blur(83.5px);
    opacity: 0.5;
  }
  &-title {
    position: relative;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.5;
    margin-bottom: 23px;
    z-index: 1;
    & b {
      font-weight: 700;
    }
    & img {
      vertical-align: text-bottom;
    }
  }
  &-subtitle {
    text-align: center;
    margin-bottom: 40px;
  }
  &-content {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 35px;
    &-left,
    &-right {
      flex-grow: 1;
    }
    &-left,
    &-right {
      width: calc(50% - 18px);
      &-inner {
        border-radius: 20px;
      }
    }
    &-left {
      &-inner {
      }
    }

    &-right {
      &-inner {
      }
    }
  }
  &-card-left,
  &-card-right {
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
      padding: 0 33px;
      border-radius: 20px 20px 0 0;
      &-left,
      &-right {
        font-family: Craftwork Grotesk;
        font-weight: 900;
      }
      &-left {
        font-size: 20px;
      }
      &-right {
        font-size: 40px;
      }
      &-center {
        width: 4px;
        height: 18px;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    &-bottom {
      padding: 30px 34px;
      background-color: $bg-color;
      border-radius: 0 0 20px 20px;
      & li {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        & img {
          margin-right: 24px;
        }
        & p {
          color: $txt-white-low;
          font-family: Inter;
          line-height: 165%;
        }
      }
    }
  }

  &-card-left {
    &-top {
      background-color: $blue;
    }
    &-bottom {
    }
  }
  &-card-right {
    &-top {
      position: relative;
      background-color: $yellow;
      &-left,
      &-right {
        color: #111213;
      }
      & img {
        position: absolute;
        top: -85%;
        left: 55%;
      }
    }
    &-bottom {
      &-unlim {
        background-color: $yellow;
        padding: 16px 21px;
        border-radius: 13px;
        margin-top: 27px;
        & p {
          max-width: 85%;
          color: $bg-color;
          font-family: Inter;
          line-height: 165%;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .tariff {
    &-card-right {
      &-top {
        & img {
          left: 50%;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .tariff {
    &::before {
    }
    &-title {
      font-size: 32px;
      text-align: left;
      & b {
      }
      & img {
        width: 50px;
        height: 50px;
        transform: translateY(2px);
      }
    }
    &-subtitle {
      position: relative;
      text-align: left;
      z-index: 1;
    }

    &-card-right {
      &-top {
        &-left,
        &-right {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .tariff {
    &::before {
      right: -100px;
      top: 100px;
    }

    &-content {
      flex-direction: column;
      align-items: center;
      &-left,
      &-right {
        width: 100%;
      }
    }
    &-card-left,
    &-card-right {
      &-top {
        height: 71px;

        &-left {
          font-size: 18px;
        }
        &-right {
          font-size: 32px;
        }
      }
      &-bottom {
        padding: 20px;
        & li {
          & p {
            font-size: 14px;
          }
        }
      }
    }

    &-card-right {
      &-top {
        & img {
          width: 67px;
          height: 74px;
          top: -30px;
          left: auto;
          right: 128px;
        }
      }
      &-bottom {
        position: relative;
        overflow: hidden;
        &::before {
          position: absolute;
          content: '';
          width: 200px;
          height: 200px;
          background: #2f1ae9;
          opacity: 0.7;
          filter: blur(83.5px);
          top: 50px;
          right: -100px;
        }
        &-unlim {
          & p {
            max-width: 100%;
            font-size: 14px;
          }
        }
      }
    }
  }
}
