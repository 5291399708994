.about-us {
  position: relative;
  padding: 100px;
  border-radius: 50px;
  background-color: #1a1a1a;
  overflow: hidden;
  margin-bottom: 60px;
  // &::before {
  //   position: absolute;
  //   content: url(../../../public/assets/about-img.webp);
  //   top: 20px;
  //   right: 50px;
  // }
  &-title {
    position: relative;
    text-transform: uppercase;
    text-align: center;
    font-size: 50px;
    margin-bottom: 60px;
    z-index: 1;
  }
  &-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 460px;
    z-index: 1;
  }
  &-card {
    width: 33%;
    padding: 0 40px 50px;
    position: relative;
    & img {
      margin-bottom: 25px;
    }
    &-title {
      font-size: 20px;
      font-weight: 900;
      line-height: 165%;
      margin-bottom: 10px;
    }
    &-txt {
      font-family: Inter;
      font-size: 14px;
      font-weight: 300;
      line-height: 140%;
      color: $txt-white-low;
    }

    &:nth-child(6),
    &:nth-child(4),
    &:nth-child(5) {
      padding: 40px 40px 20px;
    }
    &:nth-child(1)::before,
    &:nth-child(2)::before {
      position: absolute;
      content: '';
      width: 1px;
      height: 90%;
      bottom: 18px;
      right: 0;
      background-color: rgba(255, 255, 255, 0.2);
    }

    &:nth-child(4)::before,
    &:nth-child(5)::before {
      position: absolute;
      content: '';
      width: 1px;
      height: 90%;
      top: 18px;
      right: 0;
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:nth-child(1)::after {
      position: absolute;
      content: '';
      width: 90%;
      height: 1px;
      bottom: 0;
      right: 18px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    &:nth-child(2)::after {
      position: absolute;
      content: '';

      height: 1px;
      bottom: 0;
      left: 18px;
      right: 18px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    &:nth-child(3)::after {
      position: absolute;
      content: '';
      width: 90%;
      height: 1px;
      bottom: 0;
      left: 18px;
      background-color: rgba(255, 255, 255, 0.2);
    }
    &:nth-child(4)::after,
    &:nth-child(5)::after {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      top: -7px;
      right: -6.5px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      transform: rotate(45deg);
    }
  }
  &-blur1,
  &-blur2,
  &-blur3 {
    position: absolute;
    z-index: 0;
    border-radius: 50%;
    opacity: 0.7;
  }
  &-blur1 {
    width: 350px;
    height: 350px;
    top: 10%;
    left: 30%;
    background: #2f1ae9;
    filter: blur(150px);
  }
  &-blur2 {
    width: 200px;
    height: 200px;
    bottom: -10%;
    left: 5%;
    background: #439690;
    filter: blur(100px);
  }
  &-blur3 {
    width: 380px;
    height: 380px;
    right: -220px;
    top: 40%;
    background: #e2c766;
    filter: blur(145px);
  }
}

@media screen and (max-width: 1280px) {
  .about-us {
    padding: 100px 30px;
  }
}
@media screen and (max-width: 1024px) {
  .about-us {
    border-radius: 20px;
    padding: 60px 16px;
    // &::before {
    //   top: -45px;
    //   right: -60px;
    // }
    &-title {
      font-size: 32px;
      margin-bottom: 20px;
    }
    &-content {
    }
    &-card {
      padding: 24px 16px 30px;
      position: relative;
      width: 100%;
      & img {
        position: absolute;
        top: 14px;
        left: 16px;
      }
      &-title {
        font-size: 18px;
        margin-bottom: 20px;
        padding-left: 60px;
      }
      &-txt {
        position: relative;
        &:before {
          position: absolute;
          content: '';
          width: 12px;
          height: 12px;
          bottom: -32px;
          left: calc(50% - 6px);
          border: 1px solid rgba(255, 255, 255, 0.2);
          transform: rotate(45deg);
        }
        &-last::before {
          display: none;
        }
      }

      &:nth-child(6),
      &:nth-child(4),
      &:nth-child(5) {
        padding: 24px 16px 30px;
      }
      &:nth-child(1)::before,
      &:nth-child(2)::before,
      &:nth-child(3)::before,
      &:nth-child(4)::before,
      &:nth-child(5)::before {
        position: absolute;
        content: '';
        height: 1px;
        width: 45%;
        top: auto;
        right: auto;
        bottom: 4px;
        left: 0;
        background-color: rgba(255, 255, 255, 0.2);
      }
      &:nth-child(1)::after,
      &:nth-child(2)::after,
      &:nth-child(3)::after,
      &:nth-child(4)::after,
      &:nth-child(5)::after {
        height: 1px;
        width: 45%;
        top: auto;
        right: 0;
        bottom: 4px;
        left: auto;
        transform: none;
        border: none;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    &-blur1,
    &-blur3 {
      opacity: 0.3;
    }
    &-blur1 {
    }
    &-blur2 {
      display: none;
    }
    &-blur3 {
    }
  }
}
