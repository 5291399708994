.members {
  &-content {
    display: flex;
    position: relative;
    gap: 120px;
    padding: 70px 70px 0;
    border-radius: 16px;
    background: linear-gradient(180deg, #000307 0%, #011031 100%);
    overflow: hidden;
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
    &-left,
    &-right {
      flex-grow: 1;
      max-width: calc(50% - 60px);
    }
    &-left {
      flex-grow: 1;
      padding-bottom: 95px;
      z-index: 1;
    }

    &-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 55px 55px 95px;
      background-color: $dark-blue;
      border-radius: 16px 16px 0 0;
      z-index: 1;
    }
  }
  &-title {
    font-family: Craftwork Grotesk;
    font-size: 30px;
    font-weight: 500;
    line-height: 150%;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  &-text {
    color: $txt-white-low;
    font-family: Inter;
    font-size: 16px;
    line-height: 165%;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    &-right {
      color: $txt-white-low;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: italic;
      font-weight: 700;
      line-height: 165%;
      margin-bottom: 34px;
    }
  }
  &-img {
    &-top {
      margin-bottom: 30px;
    }
    &-bottom {
      max-width: 100%;
      height: auto;
    }
  }
}
@media screen and (max-width: 1300px) {
  .members {
    &-content {
      padding: 50px 50px 0;
      gap: 60px;
      justify-content: space-between;
      &-left,
      &-right {
        max-width: calc(50% - 30px);
      }
    }
    &-title {
      font-size: 28px;
    }
    &-text {
      font-size: 14px;
      &:not(:last-child) {
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .members {
    &-content {
      flex-direction: column;
      align-items: center;
      gap: 30px;
      &-left,
      &-right {
        max-width: 600px;
      }
      &-left {
        padding-bottom: 0;
      }

      &-right {
        max-width: 500px;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .members {
    &-content {
      padding: 20px 20px 0;
      &-bg {
        width: 1500px;
        filter: opacity(0.5);
        left: -100px;
      }
      &-right {
        padding: 25px 20px;
      }
    }
    &-text-right {
      max-width: 240px;
      font-size: 16px;
    }
  }
}
