.hero {
  position: relative;
  padding: 120px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;

  &::before {
    position: absolute;
    content: url(../../../public/assets/hero-img-left.webp);
    left: 6%;
    top: 36%;
    z-index: -1;
  }
  &::after {
    position: absolute;
    content: url(../../../public/assets/hero-img-right.webp);
    right: 6%;
    top: 33%;
    z-index: -1;
  }

  &-title {
    font-size: 67px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 18px;
    &-br {
      display: none;
    }
    &-icon {
      display: inline-block;
      vertical-align: top;
      transform: scale(1.1);
      margin: 0 10px 0 0;
      @media screen and (min-width: 768px) {
        margin: 0 10px;
      }
    }
  }
  &-subtitle {
    max-width: 600px;
    text-align: center;
    margin: 0 auto 70px;
    line-height: 1.6;
  }
  &-btn {
    margin-bottom: 80px;
  }
  &-bottom-desk {
    display: flex;
    gap: 15px;
    &-left,
    &-center {
      width: 336px;
      height: 230px;
      border-radius: 16px;
      padding: 30px;
      border: 1px solid #636178;
      background: rgba(255, 255, 255, 0.1);
    }
    &-left {
      &-title-top {
        font-family: Inter;
        font-size: 16px;
      }
      &-title-bottom {
        display: flex;
        align-items: center;
        font-family: Inter;
        font-size: 30px;
        font-weight: 600;
        margin: 15px 0;
        & b {
          font-family: Inter;
          font-size: 46px;
          font-weight: 600;
          margin-right: 8px;
        }
      }
    }
    &-center {
      width: 517px;
      display: flex;
      gap: 16px;
      & p {
        font-family: Inter;
        font-size: 14px;
        line-height: 1.5;
        color: $txt-white-low;
      }
      & div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & a {
          font-family: Inter;
          font-size: 16px;
          font-weight: 500;
          color: $txt-white;
        }
      }
    }
    &-right {
      width: 340px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    &-right1,
    &-right2,
    &-right3 {
      padding: 16px 10px;
      border: 1px solid #636178;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      color: $txt-white-low;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.42px;

      height: calc(50% - 8px);
    }
    &-right1 {
      width: calc(50% - 8px);
      height: calc(60% - 8px);
    }
    &-right2 {
      width: calc(50% - 8px);
      height: calc(60% - 8px);
    }
    &-right3 {
      display: flex;
      align-items: center;
      width: 100%;
      height: calc(40% - 8px);
      & img {
        margin-right: 10px;
      }
    }
  }
  &-bottom-mob {
    display: none;
  }
  &-blur1,
  &-blur2,
  &-blur3,
  &-blur4,
  &-blur5,
  &-blur6,
  &-blur7 {
    position: absolute;
    z-index: -1;
    border-radius: 50%;
  }
  &-blur1 {
    width: 450px;
    height: 450px;

    background-color: #66e1d6;
    filter: blur(120px);
    bottom: -50px;
    left: -100px;
  }
  &-blur2 {
    width: 200px;
    height: 200px;
    background-color: #2f1ae9;
    filter: blur(80px);
    bottom: -40px;
    left: 55%;
  }
  &-blur3 {
    width: 300px;
    height: 300px;
    background-color: #e2c766;
    filter: blur(200px);
    top: 100px;
    right: 0;
  }
  &-blur4 {
    width: 200px;
    height: 250px;
    background-color: #66e1d6;
    filter: blur(120px);
    top: 100px;
    right: 0;
  }
  &-blur5 {
    width: 200px;
    height: 700px;
    background-color: #2f1ae9;
    filter: blur(100px);
    bottom: -250px;
    left: 35 0px;
    transform: rotate(-5deg);
    border-radius: 50%;
    z-index: -2;
  }
  &-blur6 {
    width: 150px;
    height: 700px;
    background-color: #2f1ae9;
    filter: blur(80px);
    top: 0;
    left: 150px;
    transform: rotate(-45deg);
    border-radius: 0 0 100% 100%;
    z-index: -2;
  }
  &-blur7 {
    width: 350px;
    height: 350px;
    background-color: #2f1ae9;
    filter: blur(120px);
    top: 150px;
    left: -150px;
    border-radius: 50%;
  }
  &-cristal {
    position: absolute;
    bottom: -300px;
    right: 50px;
    width: 350px;
    height: 294px;
    z-index: 2;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: 1300px) {
  .hero {
    padding: 120px 60px;
    &::before {
      left: 6%;
      top: 27%;
    }
    &::after {
      right: 6%;
      top: 25%;
    }

    &-title {
      font-size: 48px;
      &-icon {
        & img {
          width: 80px;
          height: 60px;
        }
      }
    }

    &-bottom-desk {
      display: flex;
      flex-wrap: wrap;
      &-left,
      &-center {
        width: 100%;
      }
      &-left {
        width: calc(50% - 8px);
      }
      &-center {
        order: 1;
        & p {
          max-width: 500px;
        }
      }
      &-right {
        width: calc(50% - 8px);
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .hero {
    &-cristal {
      position: absolute;
      bottom: -75px;
      right: 20px;
      width: 180px;
      height: 134px;
    }
    padding: 120px 30px 70px;
    &::before {
      top: 25%;
      left: -50px;
    }
    &::after {
      top: 22%;
      right: -50px;
    }
    &-title {
      font-size: 36px;
      &-icon {
        & img {
          width: 70px;
          height: 50px;
          transform: translateY(-4px);
        }
      }
    }
    &-subtitle {
      font-size: 15px;
      margin-bottom: 50px;
    }
    &-bottom-desk {
      &-center {
        & p {
          max-width: 400px;
        }
      }
    }
    &-btn {
      margin-bottom: 50px;
    }
  }
}
@media screen and (max-width: 768px) {
  .hero {
    padding: 120px 16px 70px;
    &::before {
      top: 25%;
      left: -50px;
    }
    &::after {
      top: 22%;
      right: -50px;
    }
    &-title {
      text-align: left;
      width: 100%;
      &-br {
        display: block;
      }
      &-icon {
        & img {
          transform: translateY(-11px);
          margin-bottom: -20px;
        }
      }
    }
    &-subtitle {
      width: 100%;
      max-width: 700px;
      text-align: left;
    }
    &-bottom-desk {
      display: none;
      &-left,
      &-center {
        width: 336px;
        height: 230px;
        border-radius: 16px;
        padding: 30px;
        border: 1px solid #636178;
        background: rgba(255, 255, 255, 0.1);
      }
      &-left {
        width: 100%;
        margin-bottom: 10px;
        height: 158px;
        padding: 15px;
        border-radius: 10px;
        &-title-top {
          font-family: Inter;
          font-size: 13px;
        }
        &-title-bottom {
          display: flex;
          flex-direction: column;
          max-width: 100px;
          align-items: flex-start;
          font-family: Inter;
          font-size: 18px;
          margin: 12px 0 12px;

          & b {
            font-family: Inter;
            width: 100px;
            font-size: 36px;
            line-height: 36px;
            font-weight: 600;
            margin-right: 8px;
          }
        }
        & img {
          width: 110px;
          height: 42px;
        }
      }
      &-center {
        padding: 10px 15px 15px;
        width: 100%;
        flex-direction: column;
        // justify-content: space-around;
        gap: 16px;
        margin-bottom: 10px;
        border-radius: 10px;
        height: 279px;
        & > img {
          width: 103px;
          height: 81px;
          margin: 0 auto;
        }
        & p {
          font-size: 10px;
          margin-bottom: 10px;
        }
        & div {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          & a {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            color: $txt-white;
          }
        }
      }

      &-right1,
      &-right2,
      &-right3 {
        width: 100%;
        padding: 15px 10px;
        border-radius: 10px;
        font-size: 10px;
      }
      &-right1 {
        width: 100%;
        height: auto;
        height: 85px;
      }
      &-right2 {
        width: 100%;
        height: 111px;
        margin-bottom: 10px;
      }
      &-right3 {
        display: flex;
        align-items: center;
        width: 100%;
        height: 85px;
        & img {
          margin-right: 10px;
        }
      }
    }
    &-bottom-mob {
      display: flex;
      gap: 10px;
      &-left,
      &-right {
        width: calc(50% - 5px);
      }
    }
    &-btn {
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 576px) {
  .hero {
    &-btn {
      width: 100%;
    }
    &::before {
      display: none;
    }
    &::after {
      transform: rotate(-25deg);
      top: 150px;
      right: -120px;
    }
    &-blur1,
    &-blur2,
    &-blur3,
    &-blur4,
    &-blur5,
    &-blur6,
    &-blur7 {
      position: absolute;
      z-index: -1;
      border-radius: 50%;
    }
    &-blur1 {
      display: none;
    }
    &-blur2 {
      width: 150px;
      height: 150px;
      background-color: #2f1ae9;
      filter: blur(60px);
      bottom: 70px;
      left: 45%;
    }
    &-blur3 {
      width: 200px;
      height: 200px;
      background-color: #e2c766;
      filter: blur(100px);
      top: 250px;
      right: -120px;
    }
    &-blur4 {
      display: none;
    }
    &-blur5 {
      width: 50px;
      height: 300px;
      background-color: #2f1ae9;
      filter: blur(40px);
      top: 400px;
      left: 200px;
      transform: rotate(-15deg);
      border-radius: 50%;
    }
    &-blur6 {
      width: 80px;
      height: 400px;
      background-color: #2f1ae9;
      filter: blur(40px);
      top: 120px;
      left: 50px;
      transform: rotate(-35deg);
      border-radius: 0 0 100% 100%;
    }
    &-blur7 {
      width: 200px;
      height: 200px;
      background-color: #66e1d6;
      filter: blur(50px);
      top: 340px;
      left: -100px;
      opacity: 0.7;
      border-radius: 50%;
    }
  }
}
