.participants {
  position: relative;
  padding: 30px 100px 120px;
  &-title {
    position: relative;
    text-align: center;
    line-height: 165%;
    text-transform: uppercase;
    margin-bottom: 57px;
    z-index: 1;
  }
  &-subtitle {
    position: relative;
    text-align: center;
    line-height: 165%;
    margin-bottom: 20px;
    z-index: 1;
  }
  &-bottom {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    & div {
      display: flex;
      align-items: center;
    }
    & p {
      max-width: 180px;
      color: $txt-white-low;
      font-family: Inter;
      line-height: 140%;
      transform: translateY(6px);
    }

    & img {
      margin-left: 20px;
    }
  }
  &-bg {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .participants {
    position: relative;
    padding: 40px 16px;
    &-title {
      margin-bottom: 32px;
      line-height: 140%;
      margin-bottom: 50px;
    }
    &-subtitle {
      margin-bottom: 14px;
    }
    &-bottom {
      flex-direction: column;
      & div {
        margin-top: 24px;
        & img {
          margin-left: 0;
          width: 94px;
          height: 58px;
        }
      }
      & p {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 476px) {
  .participants {
    &-bottom {
      & button {
        width: 100%;
      }
    }
    &-bg {
      left: -220px;
      opacity: 0.7;
    }
  }
}
@media screen and (max-width: 376px) {
  .participants {
    &-title {
      font-size: 28px;
    }
  }
}
