.burger-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 84px);
  padding: 38px 38px 200px;
  top: 67px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 100;
  background: url(../../../public/assets/burger-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $bg-color;
}
