.form {
  width: 496px;
  max-width: 100%;
  color: $bg-color;
  overflow: hidden;
  &-title {
    color: #101820;
    text-align: center;
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &-subtitle {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    margin-bottom: 50px;
  }
  & label {
    position: relative;
    & p {
      font-family: Inter;
      font-weight: 600;
      margin-bottom: 10px;
    }
    & input {
      display: block;
      width: 100%;
      height: 60px;
      padding: 0 34px;
      border-radius: 12px;
      border: 1px solid #b5b5b5;
      background-color: #f7f7f7;
      margin-bottom: 20px;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 113%;
      outline: none;
      &::placeholder {
        color: rgba(16, 24, 32, 0.5);
      }
      &:focus,
      &:active {
        border: 1px solid $blue;
      }
    }
  }
  &-submit {
    margin-top: 50px;
    &-box {
      display: flex;
      justify-content: center;
    }
  }
  &-error {
    position: absolute;
    right: 10px;
    bottom: -26px;
    color: #ff5959;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
  &-error-border {
    border: 1px solid #ff5959 !important;
  }
}

@media screen and (max-width: 576px) {
  .form {
    &-title {
      font-size: 26px;
    }
    &-subtitle {
      font-size: 16px;
    }

    &-submit {
      margin-top: 50px;
      &-box {
        display: flex;
        justify-content: center;
      }
    }
  }
}
