.section-btn {
  position: relative;
  padding: 20px 45px 20px 20px;
  width: 250px;
  height: 70px;
  border-radius: 12px;
  background-color: $blue;
  color: $txt-white;
  font-size: 18px;
  font-family: Inter;
  cursor: pointer;
  border: none;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  & img {
    position: absolute;
    right: 18px;
    top: 19px;
  }
  &:hover {
    opacity: 0.9;
  }
}
