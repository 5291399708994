.policy {
  padding: 120px 60px 60px;
  h1 {
    margin-bottom: 8px;
  }
  h4 {
    font-size: 26px;
    margin-bottom: 16px;
    margin-top: 32px;
  }
  p {
    margin-bottom: 16px;
    margin-left: 12px;
    line-height: 1.5;
  }
  a {
    color: $blue;
    &:hover {
      opacity: 0.8;
    }
  }
  ul {
    list-style: disc;
    margin: -0 12px 20px;
  }
  li {
    margin-left: 32px;
    margin-bottom: px;
  }
}
@media screen and (max-width: 768px) {
  .policy {
    padding: 120px 20px 40px;
    h1 {
      font-size: 32px;
    }
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}
