.strategies {
  &-title {
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 1.5;
    margin-bottom: 40px;
    &-img {
      &-mob {
        display: none;
      }
    }
    & img {
      vertical-align: text-bottom;
    }
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    border-radius: 16px 16px 16px 0;
    overflow: hidden;
    &-card {
      min-height: 243px;

      &-title {
        font-family: Craftwork Grotesk;
        font-size: 20px;
        font-weight: 900;
        line-height: 120%;
        margin-bottom: 10px;
      }
      &-txt {
        max-width: 320px;
        color: $txt-white-low;
        font-family: Inter;
        font-size: 14px;
        font-weight: 300;
        line-height: 140%;
      }
    }
  }
}
.scc-1,
.scc-2,
.scc-4,
.scc-6,
.scc-7,
.scc-8,
.scc-9,
.scc-10,
.scc-12 {
  width: 20%;
}
.scc-1,
.scc-3,
.scc-4,
.scc-5,
.scc-8,
.scc-10,
.scc-11,
.scc-12 {
  & img {
    margin-bottom: 30px;
  }
}
.scc-11,
.scc-5,
.scc-3 {
  width: 40%;
}
.scc-1,
.scc-4,
.scc-10,
.scc-12 {
  padding: 20px;
  background-color: $blue;
}

.scc-8,
.scc-11,
.scc-5,
.scc-3 {
  padding: 20px;
  background-color: $dark-blue;
}
.scc-7 {
  background-color: $yellow;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scc-2,
.scc-6,
.scc-9 {
  overflow: hidden;
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1240px) {
  .strategies {
    &-title {
      text-align: center;
      font-size: 40px;
      text-transform: uppercase;
      line-height: 1.5;
      margin-bottom: 40px;
      & img {
        vertical-align: text-bottom;
      }
    }
    &-content {
      &-card {
        & img {
        }
        &-title {
          font-size: 18px;
        }
        &-txt {
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .strategies {
    padding: 60px 16px;
  }
}
@media screen and (max-width: 1024px) {
  .strategies {
    &-title {
      font-size: 32px;
      text-align: left;
      margin-bottom: 30px;
      & img {
        vertical-align: bottom;
      }
    }
    &-content {
      &-card {
        min-height: auto;
        &-title {
          font-size: 16px;
        }
        &-txt {
          font-size: 11px;
        }
      }
    }
  }

  .scc-1,
  .scc-3,
  .scc-4,
  .scc-5,
  .scc-8,
  .scc-10,
  .scc-11,
  .scc-12 {
    padding: 10px 10px 20px;
  }
}
@media screen and (max-width: 876px) {
  .strategies {
    padding: 30px 16px 60px;
    &-title {
      &-img {
        &-mob {
          display: inline-block;
        }
        &-desk {
          display: none;
        }
      }
    }

    &-content {
      border-radius: 16px 0 16px 16px;
    }
  }
  .scc-1,
  .scc-2,
  .scc-3,
  .scc-4,
  .scc-5,
  .scc-6,
  .scc-7,
  .scc-8,
  .scc-9,
  .scc-10,
  .scc-11,
  .scc-12 {
    width: 50%;
  }
  .scc-1 {
    order: 1;
  }
  .scc-2 {
    order: 2;
  }
  .scc-3 {
    order: 3;
  }
  .scc-4 {
    order: 4;
  }
  .scc-5 {
    order: 7;
    background-color: $blue;
  }
  .scc-6 {
    order: 5;
  }
  .scc-7 {
    order: 6;
  }
  .scc-8 {
    order: 8;
  }
  .scc-9 {
    order: 10;
  }
  .scc-10 {
    order: 9;
    background-color: $dark-blue;
  }
  .scc-11 {
    order: 11;
    background-color: $blue;
  }
  .scc-12 {
    order: 12;
    background-color: $dark-blue;
  }

  .scc-1,
  .scc-3,
  .scc-4,
  .scc-5,
  .scc-8,
  .scc-10,
  .scc-11,
  .scc-12 {
    & img {
      margin-bottom: 16px;
    }
  }
  .scc-2,
  .scc-6,
  .scc-9 {
    overflow: hidden;
    & img {
      max-height: 280px;
      margin-bottom: -8px;
    }
  }
}
@media screen and (max-width: 876px) {
  .scc-3,
  .scc-4,
  .scc-5,
  .scc-8,
  .scc-10,
  .scc-11,
  .scc-12 {
    min-height: 240px;
  }
}
