.thanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  & img {
    margin-bottom: 50px;
  }
  &-title {
    color: #000;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &-subtitle {
    color: rgba(16, 24, 32, 0.6);
    text-align: center;
    font-family: inter;
  }
}
